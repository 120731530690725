let Vue = require('vue/dist/vue.min');

Vue.use(require('vue-resource'));

module.exports = new Vue({
    el: '[data-password]',
    data: {
        currentLength: 0,
        lower: 0,
        capital: 0,
        numbers: 0,
        special: 0
    },
    methods: {
        computePass: function(event) {
            let value = this.$el.value;
            this.currentLength = value.length;
            this.lower = (value.match(/[a-z]/g) || []).length;
            this.capital = (value.match(/[A-Z]/g) || []).length;
            this.numbers = (value.match(/[0-9]/g) || []).length;
            this.special = (value.match(/[!@#$%^&*()_+}{';?>.<,]/g) || []).length;

            this.applyClasses();
        },
        applyClasses: function() {
            for (let name in this.$data) {
                let value = this.$data[name];
                let text = $(`[data-criteria="${name}"]`);

                if (name === 'currentLength' && value >= 8) {
                    text.removeClass('text-danger').addClass('text-success');
                } else if (name !== 'currentLength' && value > 0) {
                    text.removeClass('text-danger').addClass('text-success');
                } else {
                    text.addClass('text-danger').removeClass('text-success');
                }
            }
        }
    }
});
