import "bootstrap/dist/js/umd/collapse";
import "bootstrap/dist/js/umd/modal";
import $ from 'jquery';


// Bootstrap 4 alpha 2 still needs jquery to be defined globally...
// See: https://github.com/twbs/bootstrap/pull/16534
window.jQuery = $;

// Rate
require('./components/Rate');

// Search
if (document.querySelectorAll('[data-search]').length > 0) {
    require('./components/Search');
}

// Map
if (document.querySelectorAll('[data-map]').length > 0) {
    require('./components/Map');
}

// Password
if (document.querySelectorAll('[data-password]').length > 0) {
    require('./components/PasswordValidation');
}

// Navbar
$('.navbar-toggler').on('click', function () {
    $(this).toggleClass('active');
    $('body,.navbar-nav-container').toggleClass('push');
});

// Form
if (document.querySelectorAll('form[name="residence_review"]').length > 0) {
    require('./components/Form');
}

// Scroll to
var scrollTo = $('[data-toggle="scroll-to"]');

if (scrollTo.length > 0) {
    $('html, body').animate({
        scrollTop: scrollTo.offset().top
    }, 2000);
}

// Carousel
require('./components/carousel');
require('./components/lightbox');

var carouselResidencePhotos = $('.residence-photos .carousel');

$('.carousel-items', carouselResidencePhotos).slick({
    infinite: true,
    dots: true,
    lazyLoad: 'progressive',
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: false,
    adaptiveHeight: true,
    prevArrow: $('.carousel-arrow-prev', carouselResidencePhotos),
    nextArrow: $('.carousel-arrow-next', carouselResidencePhotos),
    appendDots: $('.carousel-dots', carouselResidencePhotos)
});
$('.carousel-items', carouselResidencePhotos).slickLightbox({
    itemSelector: '.lightbox-link',
    caption: 'caption'
});

var carouselResidenceReviewsPhotos = $('.review-photos .carousel');

$('.carousel-items', carouselResidenceReviewsPhotos).slick({
    infinite: false,
    // lazyLoad: 'progressive',
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: $('.carousel-arrow-prev', carouselResidenceReviewsPhotos),
    nextArrow: $('.carousel-arrow-next', carouselResidenceReviewsPhotos)
});
$('.carousel-items', carouselResidenceReviewsPhotos).slickLightbox({
    itemSelector: '.lightbox-link',
    caption: 'caption'
});

var carouselFavoriteResidences = $('.favorite-residences .carousel');

$('.carousel-items', carouselFavoriteResidences).slick({
    infinite: true,
    lazyLoad: 'ondemand',
    slidesToShow: 3,
    slidesToScroll: 1,
    variableWidth: false,
    autoplay: true,
    prevArrow: $('.carousel-arrow-prev', carouselFavoriteResidences),
    nextArrow: $('.carousel-arrow-next', carouselFavoriteResidences),
    responsive: [
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                adaptiveHeight: true
            }
        },
    ]
});
