var Vue = require('vue/dist/vue.min');

Vue.use(require('vue-resource'));

module.exports = new Vue({
    el: 'form[name="residence_review"]',
    data: {
        title: null,
        comment: null,
    },
    filters: {
        length: function (value) {
            return value ? value.length : 0;
        }
    }
});
